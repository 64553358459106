import { getNewAccessToken } from './getNewAccessToken'

export const refreshToken = async () => {
    try {
        const newAccessToken = await getNewAccessToken()
        sessionStorage.setItem('_at', newAccessToken)
        return newAccessToken
    } catch (error) {
        console.log('Error refreshing token:', error)
        return 'error'
    }
}
