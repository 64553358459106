import {
    DeleteManyParams,
    DeleteParams,
    GetListParams,
    GetOneParams,
    UpdateParams,
} from 'react-admin'
import axios from 'axios'
import { generateHeaders } from '../utils/generateHeaders'
import { createBaseUrl } from '../utils/createBaseUrl'
import qs from 'qs'

// or we need to change the backend response
function getSpecificPropName(obj: any) {
    delete obj.message
    return Object.keys(obj)[0]
}

// convert sort object's props to key value prop name
const refineSortObject = (sort: { field: string; order: string }) => [
    `${sort.field}:${sort.order}`,
]

export const dataProvider = {
    getList: async (resource: string, options: GetListParams) => {
        const queryParams = qs.stringify({
            pagination: {
                page: options.pagination.page,
                pageSize: options.pagination.perPage,
            },
            // sort by id
            ...(options.sort.field === 'id' && {
                sort: refineSortObject(options.sort),
            }),
            ...(options.filter.q && { search: options.filter.q }),
        })

        const response = await axios({
            url: createBaseUrl(resource, queryParams),
            method: 'GET',
            headers: generateHeaders(),
        })

        return {
            data: response.data[getSpecificPropName(response.data)],
            total: response.data.pagination.total,
        }
    },
    getOne: async (resource: string, options: GetOneParams) => {
        const response = await axios({
            url: createBaseUrl(`${resource}/${options.id}`),
            method: 'GET',
            headers: generateHeaders(),
        })

        return {
            data: {
                ...response.data[getSpecificPropName(response.data)],
            },
        }
    },
    getMany: async (resource: string, options: object) => {
        console.log('getMany')

        const res = await axios.get(resource, options)

        return res
    },
    getManyReference: async (resource: string, options: object) => {
        console.log('getManyRef')

        const res = await axios.get(resource, options)

        return res
    },
    create: async (resource: string, options: object) => {
        const res = await axios.post(resource, options)

        return res
    },
    update: async (resource: string, options: UpdateParams) => {
        const { name, surname, website, biography, status } = options.data

        const response = await axios({
            url: createBaseUrl(`${resource}/${options.id}`),
            method: 'PUT',
            headers: generateHeaders(),
            data: {
                data: {
                    name,
                    surname,
                    biography,
                    website,
                    status,
                },
            },
        })

        return {
            data: {
                id: options.id,
                ...response.data,
            },
        }
    },
    updateMany: async (resource: string) => {
        console.log('updateMany')

        const res = await axios.get(resource)

        return res
    },
    delete: async (resource: string, options: DeleteParams) => {
        const response = await axios({
            url: createBaseUrl(`${resource}/${options.id}`),
            method: 'DELETE',
            headers: generateHeaders(),
        })

        return {
            data: {
                id: options.id,
                ...response.data,
            },
        }
    },
    deleteMany: async (resource: string, options: DeleteManyParams) => {
        const deletePromises = options.ids.map(async (id: string) => {
            return await axios({
                url: createBaseUrl(`${resource}/${id}`),
                method: 'DELETE',
                headers: generateHeaders(),
            })
        })

        await Promise.all(deletePromises)

        return {
            data: options.ids,
        }
    },
}
