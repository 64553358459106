import React from 'react'
import { Admin, Resource } from 'react-admin'
import { authProvider } from '../providers/authProvider'
import { dataProvider } from '../providers/dataProvider'
import { UserList, UserEdit } from '../components/UserList'
import UsersIcon from '@mui/icons-material/People'
import MyLoginPage from '../page/MyLoginPage'

const App = () => {
    return (
        <Admin
            dataProvider={dataProvider}
            authProvider={authProvider}
            loginPage={MyLoginPage}
        >
            <Resource
                name="users"
                list={UserList}
                edit={UserEdit}
                icon={UsersIcon}
            />
        </Admin>
    )
}

export default App
