import axios from 'axios'
import { createBaseUrl } from './createBaseUrl'

export const getNewAccessToken = async () => {
    // console.log('getNewAccessToken')
    try {
        const response = await axios({
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            url: createBaseUrl('/access-token'),
            withCredentials: true,
        })
        // console.log('getNewAccessToken response', response.data.accessToken)
        return response.data.accessToken
    } catch (error) {
        console.log('Error getting new access token:', error)
        throw error
    }
}
