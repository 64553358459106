import React from 'react'
import { useState } from 'react'
import { useLogin, useNotify } from 'react-admin'
import { Box, Button, TextField } from '@mui/material'
import { grey } from '@mui/material/colors'
import TheatersIcon from '@mui/icons-material/Theaters'

const MyLoginPage = () => {
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const login = useLogin()
    const notify = useNotify()

    const handleSubmit = (e: any) => {
        e.preventDefault()
        // will call authProvider.login({ phone, password })
        login({ phone, password }).catch(() =>
            notify('Invalid phone or password', { type: 'error' })
        )
    }

    return (
        <Box
            sx={{
                display: 'flex',
                height: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: grey[900],
            }}
        >
            <form onSubmit={handleSubmit}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '2rem',
                        backgroundColor: 'white',
                        borderRadius: '0.5rem',
                        rowGap: '0.5rem',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: grey[900],
                        }}
                    >
                        <TheatersIcon
                            sx={{
                                fontSize: '3rem',
                            }}
                        />
                    </Box>
                    <TextField
                        variant="outlined"
                        label="Phone"
                        name="username"
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                    />

                    <TextField
                        variant="outlined"
                        label="Password"
                        name="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <Button type={'submit'} variant="contained">
                        Log in
                    </Button>
                </Box>
            </form>
        </Box>
    )
}

export default MyLoginPage
