import React from 'react'
import {
    Datagrid,
    EmailField,
    List,
    TextField,
    Edit,
    SimpleForm,
    TextInput,
    SelectInput,
    SearchInput,
} from 'react-admin'
import Box from '@mui/material/Box'

const userListFilters = [
    <SearchInput
        key={'search'}
        source={'q'}
        alwaysOn
        placeholder={'Search by name, surname'}
    />,
]

export const UserList = () => (
    <List filters={userListFilters}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="surname" />
            <TextField source="role" />
            <EmailField source="email" />
            <TextField source="phone" />
        </Datagrid>
    </List>
)

export const UserEdit = () => (
    <Edit>
        <SimpleForm>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(5, 1fr)',
                    width: '100%',
                    columnGap: '1rem',
                }}
            >
                <TextInput
                    source="id"
                    label={'ID'}
                    variant={'outlined'}
                    disabled
                />
                <TextInput
                    source="email"
                    label={'Email'}
                    variant={'outlined'}
                    disabled
                />
                <TextInput
                    source="phone"
                    label={'Phone'}
                    variant={'outlined'}
                    disabled
                />
                <TextInput
                    source="role"
                    label={'Role'}
                    variant={'outlined'}
                    disabled
                />
                <TextInput
                    source="status"
                    label={'Satatus'}
                    variant={'outlined'}
                    disabled
                />
                <TextInput
                    source="gender"
                    label={'Gender'}
                    variant={'outlined'}
                    disabled
                />
                <TextInput
                    source="dateOfBirth"
                    label={'Birth Date'}
                    variant={'outlined'}
                    disabled
                />

                <TextInput
                    source="education"
                    label={'Education'}
                    variant={'outlined'}
                    disabled
                />
                <TextInput
                    source="gdprAgreedAt"
                    label={'GDPR Agreed At'}
                    variant={'outlined'}
                    disabled
                />
                <TextInput
                    source="termsAgreedAt"
                    label={'Terms Agreed At'}
                    variant={'outlined'}
                    disabled
                />
                <TextInput
                    source="blockedAt"
                    label={'Blocked At'}
                    variant={'outlined'}
                    disabled
                />
                <TextInput
                    source="createdAt"
                    label={'Created At'}
                    variant={'outlined'}
                    disabled
                />
                <TextInput
                    source="updatedAt"
                    label={'Updated At'}
                    variant={'outlined'}
                    disabled
                />
                <TextInput
                    source="avatar"
                    label={'Avatar link (test)'}
                    variant={'outlined'}
                    disabled
                />
            </Box>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    width: '100%',
                    gap: '1rem',
                }}
            >
                <TextInput source="name" required />
                <TextInput source="surname" />
                <TextInput source="website" />
                <SelectInput
                    required
                    source="status"
                    choices={[
                        { id: true, name: 'Active' },
                        { id: false, name: 'Blocked' },
                    ]}
                    defaultValue={'Select status'}
                />
            </Box>
            <Box
                sx={{
                    width: '100%',
                }}
            >
                <TextInput source="biography" multiline rows={5} fullWidth />
            </Box>
        </SimpleForm>
    </Edit>
)
