import { AuthProvider } from 'react-admin'
import axios from 'axios'
import { createBaseUrl } from '../utils/createBaseUrl'
import { refreshToken } from '../utils/refreshToken'

interface LoginObjProps {
    phone: string
    password: string
}

const logout = async () => {
    await axios({
        url: createBaseUrl('/logout'),
        method: 'POST',
        withCredentials: true,
    })

    sessionStorage.removeItem('_at')
}

export const authProvider: AuthProvider = {
    // called when the user attempts to log in
    login: async (loginObj: LoginObjProps) => {
        console.log('logging in...')

        const response = await axios({
            url: createBaseUrl('/login'),
            method: 'POST',
            data: {
                phone: loginObj.phone,
                password: loginObj.password,
            },
            withCredentials: true,
        })

        const accessToken = response.data.accessToken

        const responseRole = await axios({
            url: createBaseUrl('/role'),
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            withCredentials: true,
        })

        if (responseRole.data.role !== 'admin') {
            return Promise.reject()
        }

        sessionStorage.setItem('_at', accessToken)
        return Promise.resolve()
    },
    // called when the user clicks on the logout button
    logout: async () => {
        console.log('logging out...')

        await logout()

        return Promise.resolve()
    },
    // called when the API returns an error
    checkError: async (error) => {
        console.log('check error', error)
        const response = error.response

        if (response && response.status === 401 && response.data.expired) {
            const result = await refreshToken()
            if (result === 'error') {
                await authProvider.logout({})
                return Promise.reject()
            }
        }

        return Promise.resolve()
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: async () => {
        console.log('check auth')

        return Promise.resolve()
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => {
        console.log('get permissions')

        return Promise.resolve()
    },
}
